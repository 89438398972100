.page_header
{
	padding-left: 20px;
	
	position: relative;
	background-color: #0d484e;
	margin: 0 auto;

	position: sticky;
	position: -webkit-sticky;
	top: 0; /* required */

	z-index:99999;
}

.title_wrapper
{
	margin-left: auto;
	margin-right: auto;

	display: flex;
	flex-direction: row;
	width: 78%;
}

.page_title
{
	width: 150px;
	height: 90px;
	margin-top: 25px;
	margin-bottom: 25px;
	padding-left: 10px;
	color: #ebebfc;
	font-size: 2.5rem;
	font-family: Aileron;
	max-width: 900px;
}

.title_line
{
	margin-left: auto;
	margin-right: auto;

	display: flex;
	flex-direction: row;
	width: 85%;
	color: #ebebfc;
	font-size: 1.4rem;
	font-family: Aileron;
}

.page_selection
{
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	margin-top: 60px;
	margin-left: 60px;
	margin-right: 10px; 
	max-width: 900px;
}

.page_selection_text
{
	padding: 0;
	list-style-type: none;
	min-width: 75px;

	text-decoration: none;
	color: #ebebfc;
	font-size: 1.75rem;
	font-family: Aileron;
	margin-left: 10px;
	margin-right: 10px; 
	white-space: nowrap;
}

.auth
{
	margin-top: 50px;
	margin-bottom: 30px;
}

.page_selection_text:hover
{
	color: #0d484e;
	background-color: #ecece0;

	border-radius: 8px;
}

.body_text
{
	background-color: #ecece0;
}

h1
{
	font-size: 1.75rem;
	font-family: Aileron;
}

@media all and (max-width: 768px) {
	.title_wrapper
	{
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 100%;
	}

	.page_title
	{
		margin-left: auto;
		margin-right: auto; 
		width: 50%;

		font-size: 1.6rem;
	}

	.page_selection
	{
		display: block;
		margin-left: auto;
		margin-right: auto; 
		width: 50%;
		/*max-width: 45rem;*/

	}

	.page_selection_text
	{
		margin-left: auto;
		margin-right: auto; 
		text-align: center;
		width: 100%;
	}

	.page_selection_link
	{
		text-align: center;
	}

  }
  