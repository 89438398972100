.compositeurs_table_div
{
	text-align: center;
	vertical-align: baseline;
	margin-top: 10px;

	width: 115px;
	height: 145px;
}

.compositeurs_img
{
	width: 96px;
	height: 96px;

	border-radius: 12px;
}

.compositeurs_table_div:hover
{
	opacity: 0.3;
}

.compositeur_table
{
	margin-left: auto;
	margin-right: auto; 
	width: 50%;

	display: flex;
	
	flex-wrap: wrap;
	padding: 0;
	list-style-type: none;

	display: relative;

	position: relative;
}

.visible 
{
	visibility: visible;
	opacity: 1;
	transition: opacity 1.5s linear;
}

.hidden 
{
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 1s, opacity 1s linear;
}

@keyframes slidein
{
	from { left: -100%; }
	to   { left: 0; }
}

@keyframes slideout
{
	from { left: 0; }
	to   { left: -100% }
}

@media all and (max-width: 768px) 
{
	.compositeur_table
	{
		width: 85%;
	}
}

@media (prefers-reduced-motion: reduce) 
{
	.animation 
	{
		animation: none;
	}
}
