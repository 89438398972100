.compositeur_info_div
{
	margin-left: auto;
	margin-right: auto; 
	width: 75%;

	display: flex;
	
	flex-wrap: nowrap;
	padding: 0;
	list-style-type: none;

	display: relative;

	position: relative;
}

.compositeurs_info_img_div
{
	text-align: center;
	vertical-align: baseline;
	margin-top: 10px;

	width: 307px;
	height: 337px;

	/* cursor: pointer; */
}

.compositeurs_info_img
{
	width: 288px;
	height: 288px;

	border-radius: 12px;
}

.compositeur_data_text_div
{
	padding: 0;
	list-style-type: none;
	min-width: 75px;

	text-decoration: none;
	color: #3b324a;
	font-size: 1.35rem;
	font-family: Aileron;

	margin-top: 10px;
	margin-bottom: 30px;

	border-top: 2px solid #3b324a;

	text-align: justify;
	text-justify: inter-word;
}

.compositeur_data_text_div h1
{
	font-size: 2.80rem;

	margin-top: 5px; 
	margin-bottom: 25px;
}

.compositeur_data_div
{
	margin-left: 15px;

	position: relative;
}

.edit_buttons
{
	max-height: 50px;

	margin-left: 5px;
	margin-right: 5px;

	position: sticky;
	position: -webkit-sticky;
	top: 130px; /* required */
}

@media (prefers-reduced-motion: reduce) 
{
	.animation 
	{
		animation: none;
	}
}

@media all and (max-width: 1200px) 
{
	.compositeur_info_div
	{
		flex-wrap: wrap;
	}

	.compositeurs_info_img_div
	{
		margin-left: auto;
		margin-right: auto;

		margin-top: 10px;

		width: 307px;
		height: 337px;

		/* cursor: pointer; */
	}
}